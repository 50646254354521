<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// import index from "@/views/index.vue";

export default {
  name: "App",
  components: {
    // index,
  },
  created() {
    this.$router.replace({ path: "/index" });
  },
};
</script>
<style>
body {
  margin: 0;
  padding: 0;
}
</style>
